import { i18n } from 'app/translation/i18Next.init'
import { ReactNode } from 'react'
import { Slide, ToastContainer, ToastOptions, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export type NotificationOptions = ToastOptions & {
  preventAutoClose?: boolean
  isTextTranslated?: boolean
}

const DefaultOptions: NotificationOptions & ToastOptions = {
  position: toast.POSITION.TOP_CENTER,
  isTextTranslated: false,
  pauseOnFocusLoss: false,
  preventAutoClose: false,
  transition: Slide,
}

export const Notification = {
  info: (text: string, options: NotificationOptions = DefaultOptions) => {
    toast.info(options.isTextTranslated ? text : `${i18n.t(text)}`, {
      autoClose: options.preventAutoClose ? false : 3000,
      ...options,
    })
  },
  success: (text: string, options: NotificationOptions = DefaultOptions) => {
    toast.success(options.isTextTranslated ? text : `${i18n.t(text)}`, {
      autoClose: options.preventAutoClose ? false : 3000,
      ...options,
    })
  },
  error: (text: string, options: NotificationOptions = DefaultOptions) => {
    toast.error(options.isTextTranslated ? text : `${i18n.t(text)}`, {
      autoClose: options.preventAutoClose ? false : 6000,
      ...options,
    })
  },
  warning: (text: string, options: NotificationOptions = DefaultOptions) => {
    toast.warning(options.isTextTranslated ? text : `${i18n.t(text)}`, {
      autoClose: options.preventAutoClose ? false : 6000,
      ...options,
    })
  },
  dismiss: toast.dismiss,
}

export const NotificationProvider = (props: { children: ReactNode }) => {
  return (
    <>
      <ToastContainer limit={1} position="top-center" />
      {props.children}
    </>
  )
}
