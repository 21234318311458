import { useUserViewContext } from 'app/data/user.data'
import { UserViewContext } from 'app/types/user.types'
import { Suspense, lazy } from 'react'
import { LoadingPage } from 'shared/components/layout/LoadingPage'

const InternalDashboardApp = lazy(() => import('internalDashboard/view/InternalDashboardApp'))
const PartnerPortalApp = lazy(() => import('partnerPortal/view/PartnerPortalApp'))

const Loading = () => <LoadingPage />
// const LoadingPage = () => <></>

export const UserContextRouterProvider = () => {
  const { userViewContext } = useUserViewContext()

  return (
    <Suspense fallback={<Loading />}>
      {userViewContext === UserViewContext.InternalDashboard ? (
        <InternalDashboardApp />
      ) : (
        <PartnerPortalApp />
      )}
    </Suspense>
  )
}
