import { useTracker } from 'app/data/app.data'
import { Tracker } from 'app/types/tracker.types'
import { MediaQuery } from 'frr-web/lib/theme/configure.theme'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

export const ErrorBoundaryProvider = (props: { children: ReactNode }) => {
  const { tracker } = useTracker()
  return <ErrorBoundaryInner tracker={tracker}>{props.children}</ErrorBoundaryInner>
}

type Props = {
  tracker: Tracker
}

type State = {
  hasError: boolean
}

class ErrorBoundaryInner extends React.Component<React.PropsWithChildren<Props>, State> {
  constructor(props: React.PropsWithChildren<Props>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any) {
    this.props.tracker.trackException(error)
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    const operationId = this.props.tracker.operationId()

    return (
      <Wrapper>
        <Container>
          <img
            src={'/warning.svg'}
            alt="warningStylePreviewForm.tsx"
            style={{ width: 144, height: 144 }}
          />
          <h1>An error occured</h1>
          <h3>We apologise for the inconviences.</h3>
          <p>
            The website only supports the latest versions of Chrome, Firefox, Safari and IE Edge. Please
            make sure your browser is up-to-date and try again.
          </p>
          {process.env.NODE_ENV !== 'production' && operationId && <p>Operation ID: {operationId}</p>}

          <Button
            onClick={() => {
              const win = window as any
              win.localStorage.clear()
              win.sessionStorage.clear()
              win.location.reload()
            }}
          >
            Reload application
          </Button>
        </Container>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-app);
  flex-direction: column;
`
const Container = styled.div`
  width: 580px;
  height: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.03);

  @media ${MediaQuery.Mobile} {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  & > h1 {
    max-width: 480px;
    font-size: 32px;
    font-weight: 700;
    margin: 24px 16px 8px;
    font-family: Helvetica;
    text-align: center;
  }

  & > h3 {
    max-width: 480px;
    font-size: 18px;
    opacity: 0.5;
    margin: 8px 16px;
    font-family: Helvetica;
    text-align: center;
  }

  & > p {
    max-width: 480px;
    font-size: 14px;
    opacity: 0.5;
    margin: 8px 16px 32px;
    font-family: Helvetica;
    text-align: center;
  }
`

const Button = styled.div`
  border: 0;
  width: 144px;
  height: 32px;
  color: var(--color-white);
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: rgba(80, 80, 80, 1);
  }
`
