import { useQuery } from '@tanstack/react-query'
import { AppConfig } from 'app/types/app.types'
import { UserDTO } from 'app/types/user.types'
import { mapMeToUser } from '../api/api.helpers'
import { MeDTO, MeService } from '../api/openapi'

/*
 * App query keys
 */

export const AppQueryKey = {
  AppConfig: () => ['Config'] as const,
  User: () => ['User'] as const,
}

/*
 * Config Queries
 */

const fetchConfig = async () => {
  const response = await fetch('/api/config').catch((error) => {
    throw new Error(error)
  })
  return response.json()
}

export const useAppConfigQuery = () =>
  useQuery<AppConfig>({
    queryKey: AppQueryKey.AppConfig(),
    queryFn: fetchConfig,
    staleTime: Infinity, // No re-fetching for the app configuration
  })

/*
 * User Queries
 */

export const useUserQuery = (config: AppConfig) =>
  useQuery<MeDTO, unknown, UserDTO>({
    queryKey: AppQueryKey.User(),
    queryFn: () => MeService.getMe(),
    select: (me) => mapMeToUser({ config, me }),
    staleTime: 24 * 60 * 1000, // Consider a user's data valid for 24 hours
  })
