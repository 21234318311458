import { FormThemeConfig } from 'frr-web/lib/theme/theme.form'

export const formTheme: Partial<FormThemeConfig> = {
  form: {
    wrapper: {
      flexShrink: 0,
      width: '100%',
      maxWidth: 800,
      padding: '0 0 16px',
    },
    content: {
      width: '100%',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'var(--flex-end-mobile-center)',
      height: 48,
      maxHeight: 48,
      padding: '0 16px',
      width: '100%',
      transition: 'min-height 0.2s ease-out, transform 0.2s ease-out',
      transformOrigin: 'bottom right',
      ':disabled': {
        transform: 'scale(0, 0)',
        maxHeight: 0,
      },
    },
  },
  row: {
    wrapper: {
      display: 'flex',
      minHeight: 'var(--form-row-height)',
      padding: '0 16px',
      alignItems: 'center',
      transition: 'min-height 0.2s ease-out',
      ':readonly': {
        minHeight: 28,
      },
    },
    item: {
      display: 'var(--display-flex-mobile-block)',
      alignItems: 'center',
      height: '100%',
      transition: 'min-height 0.2s ease-out',
      ':readonly': {
        width: '100%',
      },
      '@media-mobile': {
        width: '100%',
        marginTop: 12,
        marginLeft: 0,
        marginRight: 0,
        paddingBottom: 24,
      },
    },
  },
  group: {
    wrapper: {
      position: 'relative',
      marginTop: 16,
      paddingBottom: 12,
      ':readonly': {
        paddingBottom: 4,
      },
    },
    descriptionText: {
      color: 'var(--color-secondary)',
      fontSize: 'var(--font-size-p)',
      fontWeight: 400,
      marginBottom: 16,
      paddingLeft: 16,
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 16,
      paddingLeft: 16,
    },
  },
  section: {
    wrapper: {
      position: 'relative',
      marginBottom: 16,
      paddingBottom: 12,
      ':readonly': {
        paddingBottom: 4,
        marginBottom: 0,
      },
    },
    description: {
      color: 'var(--color-secondary)',
      fontSize: 'var(--font-size-p)',
      fontWeight: 400,
      marginBottom: 16,
      padding: '0 16px',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      padding: '16px 16px 12px 16px',
    },
    contentWrapper: {
      width: '100%',
    },
    content: {
      width: '100%',
    },
    introduction: {},
  },
  sectionRight: {
    wrapper: {
      position: 'absolute',
      top: 8,
      right: 16,
      width: 41,
      height: 41,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      fontSize: 'var(--font-size-h4)',
      transition: 'transform 0.3s ease-out',
      transform: 'scale(0, 0)',
      ':readonly': {
        transform: 'scale(1, 1) rotate(720deg)',
      },
      '@media-mobile': {
        position: 'absolute',
        right: 8,
        top: 16,
        width: 'auto',
        marginTop: 0,
      },
    },
    editLink: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      fontSize: 'var(--font-size-h4)',
    },
    editIcon: {
      visibility: 'hidden',
      width: 32,
      height: 32,
      padding: 6,
      borderRadius: '50%',
      transform: 'rotate(5deg)',
      transition: 'all 0.3s ease-out',
      color: 'var(--color-icon)',

      ':hover': {
        color: 'var(--color-white)',
        border: '1px solid var(--color-white)',
        backgroundColor: 'var(--color-hover)',
      },
      '@media-mobile': {
        width: 24,
        height: 24,
      },
    },
  },
  fieldMultiInput: {
    wrapper: {},
    item: {
      display: 'flex',
      width: 'var(--form-field-width)',
      alignItems: 'center',
    },
  },
  fieldReadOnly: {
    label: {
      width: 'var(--form-label-width)',
      minWidth: 'var(--form-label-width)',
      maxWidth: 'var(--form-label-width)',
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      color: 'var(--color-secondary)',
      fontSize: 'var(--font-size-p)',
      fontWeight: 300,
      '@media-mobile': { width: '100%', marginRight: 0, paddingBottom: 8 },
    },
    item: {
      fontSize: 'var(--font-size-h4)',
      wordBreak: 'break-word',
      display: 'flex',
      paddingLeft: 'calc(var(--form-field-padding-horizontal) + 1px)',
      '@media-mobile': {
        fontSize: 'var(--font-size-h3)',
        justifyContent: 'flex-start',
        paddingBottom: 8,
      },
    },
    image: {
      maxWidth: 112,
    },
    value: {
      fontSize: 'var(--font-size-p)',
      fontWeight: 600,
      marginRight: 6,
    },
    valueHighlighted: {
      fontSize: 'var(--font-size-p)',
      fontWeight: 600,
      backgroundColor: 'var(--color-status-action-required-hover)',
    },
    textAreaItem: {
      marginRight: 1,
      marginBottom: 4,
    },
    textAreaValue: {
      // height: 144,
      overflowY: 'auto',
      paddingBottom: 9,
      paddingRight: 16,
      paddingTop: 11,
      marginRight: 0,
    },
    wrapper: {
      display: 'var(--display-flex-mobile-block)',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      padding: 0,
    },
  },
}
