import { useQueryClient } from '@tanstack/react-query'
import { AppConfig, DataView, ViewOverlay } from 'app/types/app.types'
import { useDispatch, useSelector } from 'react-redux'
import { appActions, appSelectors } from './redux/appState.redux'
import { Tracker } from 'app/types/tracker.types'
import { AppQueryKey } from './queries/app.queries'

/*
 * Config data
 */

export const useAppConfig = () => {
  const queryClient = useQueryClient()
  const appConfig = queryClient.getQueryData<AppConfig>(AppQueryKey.AppConfig())

  if (!appConfig) {
    throw new Error('App config not found')
  }

  return { appConfig }
}

/*
 * Pagination data
 */

export const usePagination = () => {
  const dispatch = useDispatch()
  const actions = appActions(dispatch)
  const pagination = useSelector(appSelectors.pagination)

  return {
    pagination,
    setPagination: actions.setPagination,
  }
}

/*
 * Overlay info
 */

export const useViewOverlayOpen = (id: ViewOverlay | Array<ViewOverlay>) => {
  const openOverlays = useSelector(appSelectors.openOverlays)

  return {
    isOverlayOpen: openOverlays.some((overlay) =>
      Array.isArray(id) ? id.includes(overlay) : overlay === id,
    ),
  }
}

/*
 * Is edit form data
 */

export const useIsEditForm = () => {
  const dispatch = useDispatch()
  const actions = appActions(dispatch)
  const isEditForm = useSelector(appSelectors.isEditForm)

  return {
    isEditForm,
    setIsEditForm: actions.setIsEditForm,
  }
}

/*
 * Data view (UI or Raw JSON)
 */

const ReverseDataView = {
  [DataView.Raw]: DataView.UI,
  [DataView.UI]: DataView.Raw,
}

export const useDataView = () => {
  const dispatch = useDispatch()
  const actions = appActions(dispatch)
  const dataView = useSelector(appSelectors.dataView)

  return {
    dataView,
    toggleDataView: () => actions.setDataView(ReverseDataView[dataView]),
  }
}

/*
 * Tracker
 */

export const useTracker = () => {
  const dispatch = useDispatch()
  const actions = appActions(dispatch)
  const tracker = useSelector(appSelectors.tracker)

  return {
    tracker,
    setTracker: (tracker: Tracker) => dispatch(actions.setTracker(tracker)),
  }
}
