export const baseStyle = `
/* Font definition */

/* Variables definition */
:root {
  /* Colors */
  --color-background-app: rgb(242, 245, 249);
  --color-background-sidebar-primary: rgb(242, 245, 249);
  --color-background-sidebar-secondary: rgb(242, 245, 249, 0.3);
  --color-background-content: rgba(255, 255, 255, 1);
  --color-background-primary: rgba(255, 255, 255, 1);
  --color-background-secondary: rgba(230,244,247, 1);
  --color-background-completed: rgba(197, 229, 237, 1);
  --color-background-hover: rgba(0, 145, 178, 0.3);
  --color-background-error: rgba(166, 18, 31, 0.05);

  --color-sidebar-background: rgba(0,145,178,1);
  --color-sidebar-background-active: rgba(0, 0, 0, 0.15);
  --color-sidebar-background-hover: rgba(0, 0, 0, 0.35);
  --color-sidebar-primary:  rgba(255, 255, 255, 1);
  --color-sidebar-secondary: rgba(222, 222, 222, 1);

  --color-row-background-active: #F1FBF3; 
  --color-row-background-inactive: rgba(0,0,0,0.03);
  --color-row-background-action-required: rgba(253, 251, 239);
  --color-row-background-warning: rgba(252, 244, 243);


  --color-success: rgb(111, 242, 140);
  --color-error: rgb(166, 18, 31);
  --color-warning: rgba(255, 199, 44, 0.85);  
  --color-primary:  rgb(27, 30, 38);
  --color-secondary: rgb(124, 124, 124);
  --color-active: rgb(0, 145, 178);
  --color-hover: rgb(43, 114, 140);
  --color-fade: rgb(153,153,153);
  --color-border: rgba(0,0,0,0.05);
  --color-border-dark: rgba(153,153,153,0.25);
  --color-white: rgb(255,255,255);
  --color-black: rgb(0,0,0);
  --color-red: red;

  --color-bob-primary: rgb(0, 145, 178);
  --color-bob-secondary: #68cfd9;
  
  --color-status-active: rgb(21, 163, 73);  
  --color-status-action-required: rgba(255, 199, 44, 0.85);  
  --color-status-action-required-hover: rgba(255, 219, 51, 1);  
  --color-status-action-required-text: rgba(255, 187, 0);  

  --color-icon: rgb(154,167,184);
  --color-icon-active: rgb(86, 98, 115);

  --color-form-field-border:rgba(34,36,38,0.15);

  --color-row-success: rgb(232,252,235);

  --shadow-overlay: 2px 2px 4px rgba(0,0,0, 0.15), 0 -1px 4px rgba(0,0,0, 0.05);
  --shadow-item: 0.5px 1px 2px var(--color-form-field-border), 0 0 2px rgba(0, 0, 0, 0.05);

  /* Font family & size */
  --font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --font-size-small: 12px;
  --font-size-p: 14px;
  --font-size-h4: 14px;
  --font-size-h3: 18px;
  --font-size-h2: 24px;
  --font-size-h1: 24px;
  
  /* Header */
  --header-height: 64px;
  --header-logo-width: 242px;

  /* Sidebar */
  --sidebar-width: 256px;
  --sidebar-right-width: 256px;
  --collapsed-sidebar-width: 64px;

  /* Form */
  --form-padding-horizontal: 96px;
  --form-padding-vertical: 16px;
  --form-row-height: 56px;

  /* Form field */
  --form-label-width: 220px;
  --form-field-height: 40px;
  --form-field-width: 320px;
  --form-field-mobile-padding: 4px;
  --form-field-yes-no-option-width: 166px;
  --form-field-border-radius: 5px;
  --form-field-padding-horizontal: 16px;

  /* Multi form field */ 
  --multi-form-field-country-code-width: 80px;
  --multi-form-field-street-nr-width: 96px;
  --multi-form-field-zip-width: 96px;
  
  /* Layout */
  --display-flex-mobile-block: flex;
  --display-block-mobile-none: block;
  --flex-mobile-wrap: nowrap;
  --flex-end-mobile-center: flex-end;

  --layout-content-min-width: 734px;
  --layout-content-padding: 48px 16px 32px;
  --layout-content-content-padding: 0;
  --layout-content-right-width: 440px;
  --layout-content-right-padding: 24px 24px;
  --layout-grid-row-gap: 32px;
  --layout-speration-line: 1px solid var(--color-border);

  /* Calculator */
  --calculator-padding-top: 80px;
  --calculator-radio-group-min-width: 416px;
  --calculator-toggle-width: 96px;

}

@media (max-width: 2000px) {
  :root {
    --layout-content-right-width: 360px;
    --layout-content-right-padding: 24px;
  }
}

@media (max-width: 1800px) {
  :root {
    --layout-content-right-width: 0px;
    --layout-content-right-padding: 24px;
    --sidebar-right-width: 320px;
  }
}

@media (max-width: 1000px) {
  :root {
    --form-padding-horizontal: 64px;
    --sidebar-right-width: 256px;
  }
}

@media (max-width: 840px) {
  :root {
    /* Form field */
    --form-field-mobile-padding: 16px;
    --form-row-padding-vertical: 16px;

    /* Layout */
    --display-flex-mobile-block: block;
  }
}

@media (max-width: 640px) {
  :root {
     /* Form */
    --form-field-width: 100%;
    --form-padding-horizontal: 16px;
    --form-padding-vertical: 32px;

    /* Layout */
    --display-block-mobile-none: none;
    --flex-end-mobile-center: center;
    --layout-content-min-width: 100%;
    --layout-content-padding: 24px 8px 32px;

    /* Calculator */
    --calculator-padding-top: 40px;
    --calculator-radio-group-min-width: 100%;
    --calculator-toggle-width: 0;
  }
}

/* Base styles */

html,
body {
  font-family: var(--font-family);
  font-size: 10px;
  color:  var(--color-primary);
}


html {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

html.dark-mode {
  mix-blend-mode: difference;
  filter: hue-rotate(180deg) brightness(0.92) saturate(130%);
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

* {
  box-sizing: border-box;
}

#confetti {
  z-index: -1 !important;
  opacity: 0.5;
}

body {
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  line-height: 1.4;
}

p, a, h1, h2, h3, h4, h5, h6, div, input, select, button {
  font-family: var(--font-family);
  line-height: 1.4;
}

a {
  color: var(--color-bob-primary);
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  color: var(--color-hover);
}

b {
  font-weight: 700;
}

strong {
  font-weight: 600;
}

input,
input:focus {
  outline: none;
}

select {
  font-family: var(--font-family);
  -webkit-appearance: none;
  -moz-appearance: none;
}
select:focus {
  outline: none;
}

div {
  color: var(--color-primary);
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

/* Date picker styles */

.react-datepicker__header select {
  padding: 4px !important;
}

/* Remove the brower's default on touch styles */

button, li, a, div, span, select, input {
  -webkit-tap-highlight-color: transparent;
}
:focus {
  outline: none;
}

/* Semantic dropdown override */


.ui.selection.dropdown {
  border: 0;
  height: 33px;
  min-height: 0;
  border-radius: 4px;
}

.ui.search.dropdown>.text {
  top: -4px;
  white-space: nowrap;
  width: -webkit-fill-available;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Button animations */

@keyframes button-primary-animation {
  0% {
    background: radial-gradient(circle at center, #216991 0%, var(--color-bob-primary) 0%, var(--color-bob-primary) 100%);
  }
  25% {
    background: radial-gradient(circle at center, #216991 24%, var(--color-bob-primary) 25%, var(--color-bob-primary) 100%);
  }
  50% {
    background: radial-gradient(circle at center, #216991 49%, var(--color-bob-primary) 50%, var(--color-bob-primary) 100%);
  }
  75% {
    background: radial-gradient(circle at center, #216991 74%, var(--color-bob-primary) 75%, var(--color-bob-primary) 100%);
  }
  100% {
    background: radial-gradient(circle at center, #216991 99%, var(--color-bob-primary) 100%, var(--color-bob-primary) 100%);
  }
}

@keyframes button-secondary-animation {
  0% {
    background: radial-gradient(circle at center, rgba(242, 242, 242, 0.6) 0%, var(--color-background-primary) 0%, var(--color-background-primary) 100%);
  }
  25% {
    background: radial-gradient(circle at center, rgba(242, 242, 242, 0.6) 24%, var(--color-background-primary) 25%, var(--color-background-primary) 100%);
  }
  50% {
    background: radial-gradient(circle at center, rgba(242, 242, 242, 0.6) 49%, var(--color-background-primary) 50%, var(--color-background-primary) 100%);
  }
  75% {
    background: radial-gradient(circle at center, rgba(242, 242, 242, 0.6) 74%, var(--color-background-primary) 75%, var(--color-background-primary) 100%);
  }
  100% {
    background: radial-gradient(circle at center, rgba(242, 242, 242, 0.6) 99%, var(--color-background-primary) 100%, var(--color-background-primary) 100%);
  }
}

/* React Toastify */
.Toastify .Toastify__toast-container--top-center {
    top: 10px;
  }

.Toastify .Toastify__toast {
  min-height: 36px;
  border-radius: 4px;
}

/* Semantic UI */
.ui.dropdown .menu>.item {
  font-size: 1.3rem;
  line-height: 1.2;
}
`
